import 'intersection-observer';

import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import { NextPage } from 'next';
import type { AppContext, AppProps } from 'next/app';
import Script from 'next/script';
import { ReactElement, ReactNode } from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import store from 'redux/store';

import config from 'config';

import { createEmotionCache } from 'utils/emotion-cache.utils';
import { languageCodeMap } from 'utils/localConvert';

import { defaultLayoutService } from 'services/api';
import {
  DefaultNextSEOStrapi,
  FooterItemStrapi,
  HeaderItemStrapi,
  NotificationBanner,
} from 'services/api/types';
import { IntlProvider } from 'services/i18n';
import ThemeContext from 'services/theme-context';

import ScrollToTopButton from 'components/@common/ScrollToTop/ScrollToTop';
import { PageContainer } from 'components/@screens';
import CookiesScript from 'components/@seo/CookiesScript';
import DefaultSEO from 'components/@seo/DefaultSeo';

export interface LayoutProps {
  children: ReactNode;
}

export type NextPageWithLayout = NextPage & {
  Layout?: (props: LayoutProps) => ReactElement;
};

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  pageProps: NextPageWithLayout;
  DefaultSEO: DefaultNextSEOStrapi;
  Layout: {
    Header: HeaderItemStrapi[];
    Footer: FooterItemStrapi;
    NotificationBanner?: NotificationBanner;
  };
}

function MyApp(props: MyAppProps) {
  const { Component } = props;

  const clientSideEmotionCache = createEmotionCache();

  return (
    <CacheProvider value={clientSideEmotionCache}>
      <ThemeContext>
        <CssBaseline />
        <IntlProvider>
          <Provider store={store}>
            <PageContainer layout={props.Layout}>
              <DefaultSEO />
              <div id="back-to-top-anchor" />
              <Component {...props.pageProps} />
              {/* <GlobalLoading ref={globalLoadingRef} /> */}
              <Toaster
                reverseOrder={false}
                position="bottom-right"
                containerStyle={{ zIndex: 999999 }}
                toastOptions={{
                  duration: 5000,
                  style: {
                    fontSize: '1rem',
                  },
                }}
              />
              <ScrollToTopButton />
              <CookiesScript />
              {/* ______SECURE_LEAD_FORENSICS_____ */}
              <Script
                id="secure-lead-forensics"
                strategy="lazyOnload"
                src={config.SECURE_LEAD_FORENSICS}
                onError={(e) => console.error('Lead Forensics load failed', e)}
              />
              <noscript>
                <iframe
                  src={'https://secure.leadforensics.com/97305.png'}
                  style={{ display: 'none' }}
                />
              </noscript>
              {/* ______SECURE_LEAD_FORENSICS_____ */}
            </PageContainer>
          </Provider>
        </IntlProvider>
      </ThemeContext>
    </CacheProvider>
  );
}
export default MyApp;

MyApp.getInitialProps = async ({ ctx }: AppContext) => {
  const { locale } = ctx;

  if (locale) {
    try {
      const response = await defaultLayoutService.getLayoutData(languageCodeMap[locale]);

      if (response.data) {
        return {
          DefaultSEO: response.data?.attributes.DefaultSEO,
          Layout: {
            Footer: response.data?.attributes.Footer,
            Header: response.data?.attributes.Header,
            NotificationBanner: response.data?.attributes?.notificationBanner,
          },
        };
      } else {
        return {
          notFound: true,
          props: {},
        };
      }
    } catch (error) {
      Sentry.captureException(error);
      return {
        notFound: true,
        props: {},
      };
    }
  } else {
    return {
      notFound: true,
      props: {},
    };
  }
};
